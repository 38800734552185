


































































































































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import { UserModule } from '@/store/modules'
import { Logs } from '@/api'
import { DateManager, URLManager } from '@/services/Managers'
import { FormHelper } from '@/mixins'

enum PunishmentType {
  BAN = 0,
  MUTE = 1,
}

@Component({
  components: {
    AddPunishmentModal: () => import('@/components/AddPunishmentModal.vue'),
    Pagination: () => import('@/components/Pagination.vue'),
    AddPunishmentProofModal: () => import('@/components/AddPunishmentProofModal.vue'),
    ViewPunishmentProofModal: () => import('@/components/ViewPunishmentProofModal.vue')
  }
})
export default class CharacterLogs extends Mixins(FormHelper) {
  @UserModule.Getter currentUser

  currentPage = 1
  perPage = 10
  totalCount = null

  searchUser = ''
  searchOperator = ''
  data: any[] = []
  noResults = false
  punishmentType = null
  punishmentLogs: PunishmentLog[] = []
  tableColumns = [
    { key: 'accountId' },
    { key: 'penaltyType', label: 'Penalty', sortable: true },
    { key: 'targetName', label: 'Character', sortable: true },
    { key: 'judgeName', label: 'Operator', sortable: true },
    { key: 'duration' },
    { key: 'reason' },
    { key: 'actions', label: 'Proofs' }
  ]

  @Watch('punishmentType')
  async onLogTypeChange() {
    await this.fetchPunishmentsLogs(this.punishmentLogOptions)
  }

  @Watch('searchUser')
  @Watch('searchOperator')
  async onUserOrOperatorChange(value) {
    if (!value) {
      await this.fetchPunishmentsLogs()
      URLManager.updateURLParams('user', null)
      URLManager.updateURLParams('operator', null)
    }
  }

  get punishmentLogTypes() {
    return [
      { title: 'Mute', value: PunishmentType.MUTE, icon: 'MicOffIcon' },
      { title: 'Ban', value: PunishmentType.BAN, icon: 'UserXIcon' },
    ]
  }

  get punishmentLogOptions(): SearchPunishmentLogForm {
    const { searchOperator, searchUser } = this

    return (searchOperator || searchUser) ? {
      search: searchOperator || searchUser,
      searchType: 3,
      searchByJudgeName: searchOperator.length > 0
    } : {}
  }

  async mounted() {
    const URLParams: { [key: string]: string } = URLManager.checkURLParams(['operator', 'user', 'pageIndex'])

    this.searchUser = URLParams.user
    this.searchOperator = URLParams.operator
    this.currentPage = parseInt(URLParams.pageIndex) || 1

    await this.fetchPunishmentsLogs(this.punishmentLogOptions)
    this.$root.$on('refreshPunishmentsTable', async () => await this.fetchPunishmentsLogs(this.punishmentLogOptions))
  }

  async fetchPunishmentsLogs(punishmentLogOptions = {}) {
    try {
      const searchPunishmentLogQueries: SearchPunishmentLogQueries = {
        pageIndex: this.currentPage.toString()
      }

      if (this.punishmentType && (this.punishmentType as any).value.toString()) {
        searchPunishmentLogQueries.punishmentType = (this.punishmentType as any).value.toString()
      }
  
      const { data, totalCount } = await Logs.getPunishmentLogs(punishmentLogOptions, searchPunishmentLogQueries)

      this.punishmentLogs = data.map(e => {
        const start = new Date(e.start)
        const end = e.end ? new Date(e.end) : null

        return {
          ...e,
          start: start.toUTCString(),
          end: end ? end.toUTCString() : null,
          duration: end ? DateManager.parseTimeDelta(end, start) : `${e.time} left`,
          judgeName: e.judgeName.split('|')
        }
      })
      this.totalCount = totalCount
      URLManager.updateURLParams('pageIndex', this.currentPage)
    } catch(e) {
      console.error('Error when fetching Punishments Logs', e)
    }
  }

  async searchLog(type: 'searchOperator' | 'searchUser') {
    try {
      this.currentPage = 1
      await this.fetchPunishmentsLogs(this.punishmentLogOptions)
      URLManager.updateURLParams(type, this.punishmentLogOptions[type])
    } catch(e) {
      console.error('Error fetching Punishment logs when searching log', e)
    }
  }

  async handlePageChange(value) {
    this.currentPage = value

    await this.fetchPunishmentsLogs()
  }

  addProof() {

  }

  viewProofs(proofData) {
    this.$root.$emit('openViewPunishmentProofModal', proofData)
  }
}
